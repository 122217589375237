<template>
    <div>
        <div class="main-title">素材列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">封面图大小为：980*500</li>
                        <li data-v-69874ad0="">目前只在小程序展示</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-select v-model="category" clearable placeholder="选择分类" size="small">
                        <el-option
                            v-for="item in categorys"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l">
					<el-cascader
						style="width: 200px"
						placeholder="请选择年级"
						clearable
						v-model="selectLevelAndGrade"
						:options="options"
						:props="{ expandTrigger: 'hover' }"
						@change="changeLevelAndGrade"
						>
					</el-cascader>
                </span>
				<span class="margin-l-sm fl-l" >
				    <el-select v-model="semester" clearable placeholder="选择学期" size="small">
				       <el-option label="上册" :value="1"></el-option>
				       <el-option label="下册" :value="2"></el-option>
				    </el-select>
				</span>
                <span class="margin-l-sm fl-l" >
                    <el-date-picker
                        size="small"
                        v-model="datePick"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/excerpt/add" class="router-link" >
                        <el-button type="primary" size="small" >新增</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                    :header-cell-style="{padding:0}"
                    :cell-class-name="rowItem"
                    ref="singleTable"
                    :data="tableData"
                    highlight-current-row
                    @current-change="handleCurrentChange"
                    v-loading="loading"
                    style="width: 100%;background: #fff;">
                    <el-table-column
                        label="ID"
                        property="id"
						align="center"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        width="130"
						align="center"
                        label="封面">
                        <template slot-scope="scope">
                            <el-image class="table-item-img " :src="imageUrl+scope.row.pic" :preview-src-list="[imageUrl+scope.row.pic]">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                        property="title"
						align="center"
                        label="标题">
                    </el-table-column>
                    <!-- <el-table-column
                        property="tags"
                        label="标签">
                        <template slot-scope="scope">
                            <span v-for="tag in scope.row.tags" :key="tag">
                                <el-tag size="mini">{{tag}}</el-tag>
                            </span>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                        label="观看/点赞"
						align="center"
                        width="120">
                        <template slot-scope="scope">
                            <span>{{scope.row.view}}/{{scope.row.favour}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="收藏/分享"
						align="center"
                        width="120">
                        <template slot-scope="scope">
                            <span>{{scope.row.love}}/{{scope.row.share}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="状态" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1" class="state-up"><i></i>已上架</span>
                            <span v-if="scope.row.status == 0" class="state-down"><i></i>已下架</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        property="created_at"
						align="center"
                        label="创建时间"
                        width="200">
                    </el-table-column>
                    <el-table-column
                        width="200"
						align="center"
                        label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link v-if="scope.row.status == 1" type="primary" @click="setStatus(scope.row,scope.$index)">下架</el-link>
                                <el-link v-if="scope.row.status == 0" type="primary" @click="setStatus(scope.row,scope.$index)">上架</el-link>
                            </span> -
                            <span>
                                <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span> -
                            <span class="top">
                               <el-link  type="primary" @click="edit(scope.row)">编辑</el-link>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                        small
                        background
                        :current-page="page"
                        :page-sizes="[10, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="setPageSize"
                        @current-change="setPage"
                        :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {config} from "../../../js/config";

    export default {
        name: "list",
        data(){
            return {
                page:1,
                pageSize:10,
                total:0,
                loading:true,
                imageUrl:config.imageUrl,
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                datePick: '',
                tableData: [],
                currentRow: null,
				categorys: [],
				options: [],
				category:'',
				semester:'',
				selectLevelAndGrade: []
				
            }
        },
        methods:{
            ...mapActions('excerpt',['getExcerptList','delExcerpt','setExcerptStatus','pushExcerpt','getBasicData']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            edit(row){
                this.$router.push('/excerpt/edit/'+row.id)
                console.log(row)
            },
            setStatus(row, rowIndex){
                let _this = this
                let data = {
                    id:row.id,
                    status:!row.status,
                }

                this.loading = true
                this.setExcerptStatus(data).then(success => {
                    _this.tableData[rowIndex].status = success.data.status

                    _this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.loading = false
                },error => {
                    _this.$message({
                        message: '设置失败',
                        type: 'error'
                    });
                    this.loading = false
                })
            },
            deleted(row, rowIndex){
                let _this = this
                this.loading = true
                this.delExcerpt(row.id).then(success => {
                    _this.getList()
                    _this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                },error => {
                    _this.$message({
                        message: '删除失败',
                        type: 'error'
                    });
                })
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize
                }
				if(this.category){
					data.category = this.category
				}
				if(this.semester){
					data.semester = this.semester
				}
				if(this.selectLevelAndGrade){
					data.levelId = this.selectLevelAndGrade[0]
					data.gradeId = this.selectLevelAndGrade[1]
				}
				if(this.datePick){
					data.datePick = this.datePick
				}
                this.getExcerptList(data).then(res => {
                    this.loading = false
                    this.tableData = res.data.list
                    this.total = res.data.total
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            push(row){

                let _this = this
                this.loading = true
                this.pushExcerpt({id:row.id,type:'excerpt'}).then(success => {
                    _this.$message({
                        message: '推送成功',
                        type: 'success'
                    });
                    this.loading = false
                },error => {
                    _this.$message({
                        message: '推送失败',
                        type: 'error'
                    });
                    this.loading = false
                })
            },
			changeLevelAndGrade(e){
				this.selectLevelAndGrade = e
			}
        },
        async mounted() {
			// 获取分类基础信息
			let levels = []
			let grades = []
			let options = []
			await this.getBasicData().then(res => {
				this.categorys = res.data.category
				levels = res.data.levels
				grades = res.data.grades
			})
			levels.forEach(level => {
				let children = []
				grades.forEach(grade => {
					if(grade.level_id == level.id){
						children.push({
							value: grade.id,
							label: grade.name
						})
					}
				})
				options.push({
					value:level.id,
					label:level.name,
					children:children
				})
			})
			this.options = options
            await this.getList()
        }
    }
</script>

<style>
    .el-table .cell span.el-tag{
        margin-right: 5px;
        display: inline-block;
    }
</style>
